<!-- Switcher -->

<div class="switcher-wrapper" #switcher>
  <div class="demo_changer"> 
    <div class="form_holder sidebar-right1 ps ps--active-y">
      <ng-scrollbar >
      <div class="row">
          <div class="predefined_styles">
            <div class="swichermainleft">
              <h4>Menu Estilo</h4>
              <div class="skin-body">
                <div class="switch_section">
                  <div class="switch-toggle d-flex">
                    <span class="me-auto">Menu Vertical</span>
                  <p class="onoffswitch2 my-0">
                      <input
                        type="radio"
                        name="onoffswitch15"
                        id="myonoffswitch34"
                        class="onoffswitch2-checkbox"
                        checked
                      />
                      <label
                        for="myonoffswitch34"
                        class="onoffswitch2-label"
                      ></label>
                    </p>
                  </div>
                  <div class="switch-toggle d-flex mt-2">
                    <span class="me-auto">Menu Horizontal</span>
                    <p class="onoffswitch2 my-0">
                      <input 
                        type="radio"
                        name="onoffswitch15"
                        id="myonoffswitch35"
                        class="onoffswitch2-checkbox"
                      />
                      <label
                        for="myonoffswitch35"
                        class="onoffswitch2-label"
                      ></label>
                    </p>
                  </div>
                  <div class="switch-toggle d-flex mt-2">
                    <span class="me-auto">Menu Horizontal Auto</span>
                    <p class="onoffswitch2 my-0">
                      <input
                        type="radio"
                        name="onoffswitch15"
                        id="myonoffswitch111"
                        class="onoffswitch2-checkbox"
                      />
                      <label
                        for="myonoffswitch111"
                        class="onoffswitch2-label"
                      ></label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="swichermainleft">
              <h4>Tema</h4>
              <div class="skin-body">
                <div class="switch_section">
                  <div class="switch-toggle d-flex">
                    <span class="me-auto ">Modo Claro</span>
                    <p class="onoffswitch2">
                      <input
                        type="radio"
                        name="onoffswitch1"
                        id="myonoffswitch1"
                        class="onoffswitch2-checkbox"
                        checked
                      />
                      <label
                        for="myonoffswitch1"
                        class="onoffswitch2-label"
                      ></label>
                    </p>
                  </div>
                  <div class="switch-toggle d-flex mt-2">
                    <span class="me-auto">Modo Obscuro</span>
                    <p class="onoffswitch2">
                      <input
                        type="radio"
                        name="onoffswitch1"
                        id="myonoffswitch2"
                        class="onoffswitch2-checkbox"
                      />
                      <label
                        for="myonoffswitch2"
                        class="onoffswitch2-label"
                      ></label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="swichermainleft">
              <h4>Menu</h4>
              <div class="skin-body">
                <div class="switch_section">
                  <div class="switch-toggle lightMenu d-flex">
                    <span class="me-auto">Menu Claro</span>
                    <p class="onoffswitch2">
                      <input 
                        type="radio"
                        name="onoffswitch2"
                        id="myonoffswitch3"
                        class="onoffswitch2-checkbox" 
                        checked = true
                      />
                      <label
                        for="myonoffswitch3"
                        class="onoffswitch2-label"
                      ></label>
                    </p>
                  </div>
                  <div class="switch-toggle darkMenu d-flex mt-2">
                    <span class="me-auto">Menu Obscuro</span>
                    <p class="onoffswitch2">
                      <input
                        type="radio"
                        name="onoffswitch2"
                        id="myonoffswitch5"
                        class="onoffswitch2-checkbox"
                      />
                      <label
                        for="myonoffswitch5"
                        class="onoffswitch2-label"
                      ></label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="swichermainleft">
              <h4>Encabezado</h4>
              <div class="skin-body">
                <div class="switch_section">
                  <div class="switch-toggle lightHeader d-flex">
                    <span class="me-auto">Encabezado Claro</span>
                    <p class="onoffswitch2">
                      <input
                        type="radio"
                        name="onoffswitch3"
                        id="myonoffswitch6"
                        class="onoffswitch2-checkbox"
                        checked = true
                      />
                      <label
                        for="myonoffswitch6"
                        class="onoffswitch2-label"
                      ></label>
                    </p>
                  </div>
                  <div class="switch-toggle darkHeader d-flex mt-2">
                    <span class="me-auto">Encabezado Obscuro</span>
                    <p class="onoffswitch2">
                      <input
                        type="radio"
                        name="onoffswitch3"
                        id="myonoffswitch8"
                        class="onoffswitch2-checkbox"
                      />
                      <label
                        for="myonoffswitch8"
                        class="onoffswitch2-label"
                      ></label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="swichermainleft">
              <h4>Resetear</h4>
              <div class="skin-body">
                <div class="switch_section my-4">
                  <button
                    class="btn btn-danger btn-block"
                    (click)="reset()"
                    type="button"
                  >
                    Tema Original
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
     </ng-scrollbar> 
      </div>
  </div>
</div>
<!-- End Switcher -->

<ng-template #content let-modal>
    <!-- <div class="modal buynow show" aria-modal="true" role="dialog" style="display: block;"> -->
        <div class=" buynow" role="document">
            <div class=" modal-content-demo buynow-bg" data-bs-image-src="./assets/switcher/img/16.jpg"style="background: url(&quot;./assets/switcher/img/16.jpg&quot;) center center;">
                <div class="modal-body  px-0">
                    <div class="row justify-content-center py-4 px-0 mx-3  Licenses-img">
                        <h3 class=" text-center mb-4 text-white">Licenses</h3>
                        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true" (click)="modal.dismiss('Cross click')">×</span></button>
                            <div class="col-sm-10 col-md-8 col-xl-5 col-lg-5">
                                <div class="card  border-0 regular-license">
                                    <div class="card-body imag-list cover-image" data-bs-image-src="./assets/switcher/img/14.jpg" style="background: url(&quot;./assets/switcher/img/14.jpg;) center center;">
                                        <div class="text-white">
                                            <img src="./assets/switcher/img/free.png" alt="" class="w-55 free-img">
                                            <div class="text-center">
                                                <div class="tx-26"><span class="fw-medium ">Regular</span> Licenses</div>
                                                <p class="fw-semi-bold mb-sm-2 mb-0">You <span class="text-success fw-semibold">can't charge </span> from your <br><span class="op-8">End Product  End Users</span> </p>
                                                <div ngbDropdown  class="dropdown">
                                                    <button ngbDropdownToggle class="btn btn-info w-lg dropdown-toggle mt-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Buy Now
                                                    </button>
                                                    <ul ngbDropdownMenu  class="dropdown-menu py-0">
                                                        <a ngbDropdownItem class="dropdown-item  border-bottom px-3" target="_blank" href="https://themeforest.net/user/spruko/portfolio">
                                                            <div>
                                                                <p class="tx-14 mb-0 lh-xs fw-semibold">Buy Now</p><span class="tx-12 op-7 ">6 months support</span>
                                                            </div>
                                                        </a>
                                                        <a ngbDropdownItem class="dropdown-item   px-3" target="_blank" href="https://themeforest.net/user/spruko/portfolio">
                                                            <div>
                                                                <p class="tx-14 mb-0 lh-xs fw-semibold">Buy Now</p><span class="tx-12 op-7 ">12 months support</span>
                                                            </div>
                                                        </a>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-10 col-md-8 col-xl-5 col-lg-5">
                            <div class="card border-0 ">
                                <div class="card-body imag-list cover-image" data-bs-image-src="./assets/switcher/img/15.jpg" style="background: url(&quot;./assets/switcher/img/15.jpg&quot;) center center;">
                                    <div class="text-white">
                                        <img src="./assets/switcher/img/money-bag.png" alt="" class="w-55 free-img">
                                        <div class="text-center">
                                            <div class="tx-26"><span class="fw-medium ">Extended</span> Licenses</div>
                                            <p class="fw-semi-bold mb-sm-2 mb-0">You  <span class="text-warning fw-semibold">can charge</span> from your  <br><span class="op-8">End Product  End Users</span></p>
                                            <div ngbDropdown class="dropdown">
                                                <button ngbDropdownToggle class="btn btn-info w-lg dropdown-toggle mt-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Buy Now
                                                </button>
                                                <ul ngbDropdownMenu class="dropdown-menu py-0">
                                                    <a ngbDropdownItem class="dropdown-item  border-bottom px-3" target="_blank" href="https://themeforest.net/user/spruko/portfolio">
                                                        <div>
                                                            <p class="tx-14 mb-0 lh-xs fw-semibold">Buy Now</p><span class="tx-12 op-7 ">6 months support</span>
                                                        </div>
                                                    </a>
                                                    <a ngbDropdownItem class="dropdown-item   px-3" target="_blank" href="https://themeforest.net/user/spruko/portfolio">
                                                        <div>
                                                            <p class="tx-14 mb-0 lh-xs fw-semibold">Buy Now</p><span class="tx-12 op-7 ">12 months support</span>
                                                        </div>
                                                    </a>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="license-view">
                                <a href="https://spruko.com/licenses" target="_blank" class="modal-title text-center mb-3 tx-14 text-white">View license details</a>
                            </div>
                         </div>
                 </div>
            </div> 
        </div>
    <!-- </div> -->
</ng-template>
