<div ngbDropdown class="d-flex message dropdown">
    <a ngbDropdownToggle data-bs-toggle="dropdown" class="dropdown-toggle nav-link icon caret" aria-expanded="true">
        <i class="fa fa-address-book-o"></i>
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
        <div ngbDropdownItem class="p-4 border-bottom" tabindex="0">
            <h5 class="border-bottom-0 mb-0 fs-16 font-weight-semibold"> Directorio </h5>
        </div>
        <div class="p-4 border-bottom" tabindex="0">
            <div *ngIf="error" class="alert alert-danger">
                {{ error }}
            </div>
            <!-- Campo de filtro --> 
            <input type="text" class="form-control" [(ngModel)]="filtro" (input)="aplicarFiltro()"
                placeholder="Buscar por nombre o cargo" />
        </div>
        <div class="message-menu message-menu-scroll message-menu">
            <!-- Lista del directorio -->
            <ng-container *ngFor="let agencia of agenciasFiltradas">
                <span class="dropdown-item d-flex" *ngFor="let persona of agencia.directorio">
                    <span class="avatar brround me-3 cover-image">
                        {{agencia.agencia}}
                    </span>
                    <div class="w-90">
                        <div class="d-flex">
                            <h6 class="my-1 text-break">{{persona.nombre}}</h6>
                        </div>
                        <small class="text-muted mb-0">{{persona.cargo}}</small>
                        <br>
                        <ng-container *ngFor="let t of procesarNumeros(persona.telefono)">
                            <a href="tel:{{t.whatsapp}}{{t.extension}}" class="text-gray ms-auto text-wrap fs-12 mb-0" (click)="copyText(t.numero)"><i class="fa fa-phone"></i> {{t.numero}}<ng-container *ngIf="t.esCelular">&nbsp;&nbsp;<a href="https://wa.me/{{t.whatsapp}}" target="_blank"><i class="fa fa-whatsapp text-success"></i></a></ng-container></a>
                            <br>
                        </ng-container>
                        <a *ngIf="persona.correo" href="mailto:{{persona.correo}}" class="text-gray ms-auto text-wrap fs-12 mb-0" (click)="copyText(persona.correo)"><i class="fa fa-envelope"></i> {{persona.correo}}</a>
                    </div>
                </span>
            </ng-container>

            <!-- Mostrar mensaje si no hay resultados después del filtrado -->
            <span *ngIf="!agenciasFiltradas.length" ngbDropdownItem class="text-muted">No se encontraron resultados.</span>
        </div>
    </div>
</div>