<div class="sidebar sidebar-right sidebar-animate" #rightsidebar >
    <div class="">
         <a href="javascript:void(0);" (click)="onClose()" class="sidebar-icon text-end float-end" data-bs-toggle="sidebar-right" data-bs-target=".sidebar-right"><i class="fe fe-x"></i></a>
     </div>
     <div class="p-3 border-bottom">
         <h5 class="border-bottom-0 mb-0">General Settings</h5>
     </div>
     <div class="p-4">
         <div class="switch-settings">
             <div class="d-flex mb-2">
                 <span class="me-auto fs-15">Notifications</span>
                 <label class="custom-switch">
                     <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input">
                     <span class="custom-switch-indicator"></span>
                 </label>
             </div>
             <div class="d-flex mb-2">
                 <span class="me-auto fs-15">Show your emails</span>
                 <label class="custom-switch">
                     <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input">
                     <span class="custom-switch-indicator"></span>
                 </label>
             </div>
             <div class="d-flex mb-2">
                 <span class="me-auto fs-15">Show Task statistics</span>
                 <label class="custom-switch">
                     <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input">
                     <span class="custom-switch-indicator"></span>
                 </label>
             </div>
             <div class="d-flex mb-2">
                 <span class="me-auto fs-15">Show recent activity</span>
                 <label class="custom-switch">
                     <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input">
                     <span class="custom-switch-indicator"></span>
                 </label>
             </div>
             <div class="d-flex mb-2">
                 <span class="me-auto fs-15">System Logs</span>
                 <label class="custom-switch">
                     <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input">
                     <span class="custom-switch-indicator"></span>
                 </label>
             </div>
             <div class="d-flex mb-2">
                 <span class="me-auto fs-15">Error Reporting</span>
                 <label class="custom-switch">
                     <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input">
                     <span class="custom-switch-indicator"></span>
                 </label>
             </div>
             <div class="d-flex mb-2">
                 <span class="me-auto fs-15">Show your status to all</span>
                 <label class="custom-switch">
                     <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input">
                     <span class="custom-switch-indicator"></span>
                 </label>
             </div>
             <div class="d-flex mb-2">
                 <span class="me-auto fs-15">Keep up to date</span>
                 <label class="custom-switch">
                     <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input">
                     <span class="custom-switch-indicator"></span>
                 </label>
             </div>
         </div>
     </div>
     <div class="p-3 border-bottom">
         <h5 class="border-bottom-0 mb-0">Overview</h5>
     </div>
     <div class="p-4">
         <div class="progress-wrapper">
             <div class="mb-3">
                 <p class="mb-2">Achieves<span class="float-end text-muted font-weight-normal">80%</span></p>
                 <div class="progress h-1">
                     <div class="progress-bar bg-primary w-80 " role="progressbar"></div>
                 </div>
             </div>
         </div>
         <div class="progress-wrapper pt-2">
             <div class="mb-3">
                 <p class="mb-2">Projects<span class="float-end text-muted font-weight-normal">60%</span></p>
                 <div class="progress h-1">
                     <div class="progress-bar bg-secondary w-60 " role="progressbar"></div>
                 </div>
             </div>
         </div>
         <div class="progress-wrapper pt-2">
             <div class="mb-3">
                 <p class="mb-2">Earnings<span class="float-end text-muted font-weight-normal">50%</span></p>
                 <div class="progress h-1">
                     <div class="progress-bar bg-success w-50" role="progressbar"></div>
                 </div>
             </div>
         </div>
         <div class="progress-wrapper pt-2">
             <div class="mb-3">
                 <p class="mb-2">Balance<span class="float-end text-muted font-weight-normal">45%</span></p>
                 <div class="progress h-1">
                     <div class="progress-bar bg-warning w-45 " role="progressbar"></div>
                 </div>
             </div>
         </div>
         <div class="progress-wrapper pt-2">
             <div class="mb-3">
                 <p class="mb-2">Toatal Profits<span class="float-end text-muted font-weight-normal">75%</span></p>
                 <div class="progress h-1">
                     <div class="progress-bar bg-danger w-75" role="progressbar"></div>
                 </div>
             </div>
         </div>
         <div class="progress-wrapper pt-2">
             <div class="mb-3">
                 <p class="mb-2">Total Likes<span class="float-end text-muted font-weight-normal">70%</span></p>
                 <div class="progress h-1">
                     <div class="progress-bar bg-teal w-70" role="progressbar"></div>
                 </div>
             </div>
         </div>
     </div>
 </div>
 <!-- SIDE-BAR CLOSED -->