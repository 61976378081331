import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, finalize, from, map, switchMap, } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { micro } from 'src/app/shared/routes/micros.enum';
import Swal from 'sweetalert2';
import jwtDecode from 'jwt-decode';
import { JwtAmbyPayload } from 'src/app/shared/interfaces/JwtAmbyPayLoad.interface';

const microFirstToken = [micro.seguridadesCommandApi, micro.seguridadesQueryApi, micro.notificationServiceApi]

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private auth: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request.url.split('/');
    const microReq = url[3];

    if (this.noToken(url)) {
      return next.handle(request);
    }

    
    if (this.microsOnlyFirstToken(microReq)) {
      const req = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.getToken}` }
      });
      return next.handle(req);
    } else {
      
      if (this.auth.isExpiredToken()) {
        return EMPTY;
      }

      const local = this.getMicroToken(microReq);

      if (local) {
        const req = request.clone({
          setHeaders: { 'Authorization': `Bearer ${local}` }
        });
        return next.handle(req);
      } else {
        Swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () =>
            Swal.showLoading()
        });
        return this.getAuthToken(microReq, request).pipe(
          finalize(() =>{
            Swal.fire({
              allowOutsideClick: false,
              allowEscapeKey: false,
              didOpen: () =>
                Swal.showLoading()
            });
          }),
          switchMap(x => {
            return next.handle(x);
          })
        );
      }
    }
  }

  get getToken(): string {
    return localStorage.getItem('token') || '';
  }

  get getIp(): string {
    return localStorage.getItem('ipAddress') || '';
  }


  noToken(url: string[]): boolean {
    if (url[3] === micro.seguridadesCommandApi && url[5] === 'Login' || url[3] === 'rrhh' || url[3] === 'wp-json' || url[3] === 'archivos') {
      return true;
    }
    else {
      return false;
    }
  }

  microsOnlyFirstToken(micro: string): boolean {
    return microFirstToken.find(md => md == micro) != undefined;
  }


  private getAuthToken(micro: string, original: HttpRequest<any>): Observable<HttpRequest<any>> {
    return from(this.auth.generarTokenQuery(this.getIp, micro))
      .pipe(
        map(token => {
          const req = original.clone({
            setHeaders: { 'Authorization': `Bearer ${token.data.jwToken}` }
          });
          localStorage.setItem(micro, token.data.jwToken)
          return req
        })
      )
  }

  private getMicroToken(micro:string): string | null {
    try {
      const token = localStorage.getItem(micro);
      if(token && token !== null ){
        const decoded = jwtDecode<JwtAmbyPayload>(token);
        const tokenExpired = Date.now() > decoded.exp! * 1000;
        if(!tokenExpired){
          return token;
        }
      }

      return null;

    } catch (error) {
      return null;
    }
  }

}
