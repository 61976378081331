import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { environment } from 'src/environments/environment.development';
import { ICalendarEventAmbysoft, INotificacionAmbysoft } from '../interfaces/notificacions.interfaces';
import { map, Observable } from 'rxjs';
import { Response } from 'src/app/interfaces/ApiResponse/response.interfaces';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private hubConnection!: HubConnection;
  private modoDesarrollo = true;

  constructor(private http: HttpClient) {
    this.modoDesarrollo = !environment.production;
  }

  public inicializarConector() {
    const op: IHttpConnectionOptions = {
      accessTokenFactory: () => localStorage.getItem('token') || '',
    };
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(
        `${environment.url}/${environment.notificationApi}${environment.notificationHub}`,
        op
      )
      .withAutomaticReconnect()
      .build();
  }

  public conectar() {
    this.hubConnection
      .start()
      .then(() => {
        if (this.modoDesarrollo) {
          console.log('Conectado al Hub de notificaciones');
        }
      })
      .catch((err) => {
        if (this.modoDesarrollo) {
          console.log('Error en conexión con el Hub de notificaciones: ' + err);
        }
      });
  }

  public stopConnection() {
    this.hubConnection.stop().then(() => {
      if (this.modoDesarrollo) {
        console.log('Conexión perdida con Ambysoft notification service.');
      }
    });
  }
  
  public registrarHandlerNotificacion(notificationHandler: (message: any) => void) {
    this.hubConnection.on('ReceiveNotification', notificationHandler);
  }

  public agregarUsuarioAGrupo(groupName: string) {
    this.hubConnection.invoke('ChatJoinGroup', groupName);
  }

  public quitarUsuarioAGrupo(groupName: string) {
    this.hubConnection.invoke('ChatLeaveGroup', groupName);
  }

  public chatUsuarioGrupo(mensaje: string, grupo?: string, usuarioDestino?: string) {
    this.hubConnection.invoke('SendChat', { grupo, usuarioDestino, mensaje });
  }

  ObtenerNotificacionesUsuario():Observable<INotificacionAmbysoft[]>{
    return this.http.get<any>
      (`${environment.url}/${environment.notificationApi}/api/notifications/get`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            'Content-Type': 'application/json',
          }
        })
      .pipe(map(response => response));
  }

  LeerNotificacionesUsuario(idNotificacion: number):Observable<null>{
    return this.http.post<null>
      (`${environment.url}/${environment.notificationApi}/api/notifications/read?id=${idNotificacion}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            'Content-Type': 'application/json',
          }
        })
      .pipe(map(response => null));
  }

  public mostrarNotificacion(notificacion: INotificacionAmbysoft): void {
    if(notificacion.idNotificacion && !notificacion.esLeida){
      notificacion.esLeida = true;
      this.LeerNotificacionesUsuario(notificacion.idNotificacion ?? 0)
          .subscribe();
    }
    Swal.fire({
      title: notificacion.titulo || 'Notificación',
      html: `
        <div class="me-1 notifyimg ${ notificacion.color ?? 'gb-primary' } brround box-shadow">
            <i class="${ notificacion.icono ?? 'fa fa-bell' }"></i>
        </div>
        <p><strong>Mensaje:</strong> ${notificacion.mensaje}</p>
        <p><strong>Fecha:</strong> ${new Date(notificacion.fecha!).toLocaleString()}</p>
        <p><strong>Usuario:</strong> ${notificacion.usuario}</p>
      `,
      icon: 'info',
      showCancelButton: !!notificacion.url,  // Mostrar botón "Ir al enlace" si existe URL
      confirmButtonText: notificacion.url ? 'Ir al enlace' : 'OK',
      cancelButtonText: 'Cerrar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed && notificacion.url) {
        window.open(notificacion.url, notificacion.target || '_blank');
      }
    });
  }

  ObtenerCalendarioUsuario(fechaInicio: string, fechaFin: string):Observable<ICalendarEventAmbysoft[]>{
    return this.http.get<any>
      (`${environment.url}/${environment.notificationApi}/api/calendar/get?fi=${fechaInicio}&ff=${fechaFin}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            'Content-Type': 'application/json',
          }
        })
      .pipe(map(response => response));
  }

}
