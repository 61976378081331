import { Component, OnInit } from '@angular/core';
import {  NavService } from 'src/app/shared/services/nav.service'
import { LayoutService } from 'src/app/shared/services/layout.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SwitcherService } from 'src/app/shared/services/switcher.service';
import { Menu } from 'src/app/shared/interfaces/Menu.interfaces';
import jwtDecode from 'jwt-decode';
import { JwtAmbyPayload } from 'src/app/shared/interfaces/JwtAmbyPayLoad.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public isCollapsed = true;
  public nombreUsuario:string = '';
  public rol?:string;

  constructor(
    private layoutService: LayoutService,
    public navServices: NavService,
    public modalService: NgbModal,
    public SwitcherService: SwitcherService,
    public router: Router
  ) {

  }

  ngOnInit(): void {
    this.navServices.items.subscribe((menuItems) => {
      this.items = menuItems;
    });
    try {
      const token = localStorage.getItem('token');
      if(token && token !== null ){
        const decoded = jwtDecode<JwtAmbyPayload>(token);
        this.nombreUsuario = `${decoded.name || ''} (${decoded.em} ${decoded.ag})`;
        this.rol = decoded.rnm;
      }
    } catch (error) { }
  }

  //  open(content:any) {
  //   this.modalService.open(content, {
  //     backdrop: 'static',
  //     windowClass: 'modalCusSty',
  //     size: 'lg',
  //   });
  // }

  toggleSidebar() {
    if ((this.navServices.collapseSidebar = true)) {
      document.querySelector('body')?.classList.toggle('sidenav-toggled');
    }
  }

  toggleSidebarNotification() {
    this.layoutService.emitSidebarNotifyChange(true);
  }

  toggleSwitcher() {
    this.SwitcherService.emitChange(true);
    document.querySelector('body')?.classList.remove('sidenav-toggled-open');
  }

  // Search
  public menuItems!: Menu[];
  public items!: Menu[];
  public text!: string;
  public SearchResultEmpty: boolean = false;

  Search(searchText: any) {
    if (!searchText) return (this.menuItems = []);
    // items array which stores the elements
    let items: any[] = [];
    // Converting the text to lower case by using toLowerCase() and trim() used to remove the spaces from starting and ending
    searchText = searchText.toLowerCase().trim();
    this.items.filter((menuItems: any) => {
      // checking whether menuItems having title property, if there was no title property it will return
      if (!menuItems?.title) return false;
      //  checking wheteher menuitems type is text or string and checking the titles of menuitems
      if (
        menuItems.type === 'link' &&
        menuItems.title.toLowerCase().includes(searchText)
      ) {
        // Converting the menuitems title to lowercase and checking whether title is starting with same text of searchText
        if (menuItems.title.toLowerCase().startsWith(searchText)) {
          // If you want to get all the data with matching to letter entered remove this line(condition and leave items.push(menuItems))
          // If both are matching then the code is pushed to items array
          items.push(menuItems);
        }
      }
      //  checking whether the menuItems having children property or not if there was no children the return
      if (!menuItems.children) return false;
      menuItems.children.filter((subItems: any) => {
        if (
          subItems.type === 'link' &&
          subItems.title.toLowerCase().includes(searchText)
        ) {
          if (subItems.title.toLowerCase().startsWith(searchText)) {
            // If you want to get all the data with matching to letter entered remove this line(condition and leave items.push(subItems))
            items.push(subItems);
          }
        }
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems: any) => {
          if (subSubItems.title.toLowerCase().includes(searchText)) {
            if (subSubItems.title.toLowerCase().startsWith(searchText)) {
              // If you want to get all the data with matching to letter entered remove this line(condition and leave items.push(subSubItems))
              items.push(subSubItems);
            }
          }
        });
        return;
      });
      return (this.menuItems = items);
    });
    // Used to show the No search result found box if the length of the items is 0
    if (!items.length) {
      this.SearchResultEmpty = true;
    } else {
      this.SearchResultEmpty = false;
    }
    return;
  }

  clearSearch() {
    this.text = '';
    this.menuItems = [];
    this.SearchResultEmpty = false;
    return this.text, this.menuItems;
  }

  cerrarSesion(){
    for (let index = localStorage.length - 1; index >= 0; index--) {
      const k = localStorage.key(index) || '';
      if(!k.includes("modoObscuro") && !k.startsWith("Yoha") && !k.startsWith("Amby") && !k.includes("notDev") && !k.includes("notUsr")){
        localStorage.removeItem(k);
      }
    }
    this.router.navigateByUrl('auth/login');
  }
}
