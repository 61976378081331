import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { INotificacionAmbysoft } from 'src/app/shared/interfaces/notificacions.interfaces';
import { NotificationService } from 'src/app/shared/services/notification.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styles: [
  ]
})
export class NotificacionComponent implements OnInit, AfterViewInit, OnDestroy {

  public notificaciones:INotificacionAmbysoft[] =[];
  public tieneSinLeer: boolean = false;

  constructor(private notificationService: NotificationService){}

  ngOnInit(): void {
      this.notificationService.inicializarConector();
      this.notificationService.conectar();
      this.notificationService.registrarHandlerNotificacion(this.handleNotificacion.bind(this))
      //Cargar las ultimas notificaciones
      this.ObtenerNotificacionesUsuario();
  }

  ngAfterViewInit(): void {
    //Actualizar si existen no leidas
    this.actualizaLeidas();
  }

  ngOnDestroy(): void {
    this.notificationService.stopConnection();
  }

  handleNotificacion(mensaje: INotificacionAmbysoft) {
    this.playSound();
    this.notificaciones.push(mensaje);
    this.actualizaLeidas();
  }

  public playSound() {
    const audio = new Audio('./assets/audio/message.mp3');
    audio.play();
  }

  actualizaLeidas(){
    this.tieneSinLeer = this.notificaciones.filter(x=> x.esLeida === false).length > 0;
  }

  mostrarNotificacion(notificacion: INotificacionAmbysoft): void {
    this.notificationService.mostrarNotificacion(notificacion);
    this.actualizaLeidas();
  }

  getNotificacionesRecientes(): INotificacionAmbysoft[] {
    if(!this.notificaciones)
      return [];
    return this.notificaciones
      .sort((a, b) => new Date(b.fecha!).getTime() - new Date(a.fecha!).getTime()) // Ordenar por fecha descendente
      .slice(0, 15); // Obtener solo las primeras 15
  }
  
  ObtenerNotificacionesUsuario() {
    this.notificationService
    .ObtenerNotificacionesUsuario()
      .subscribe({
        next: (data) => {
          this.notificaciones = data;
        }
      })
  }

}
