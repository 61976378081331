<div class="horizontalMenucontainer">
  <div class="page">
     <app-header ></app-header>
      <app-switcher></app-switcher>
      <div class="page-main">
        <!-- // header -->
        <!-- //sidebar -->
        <app-sidebar appHoverEffectSidebar></app-sidebar>
         
        <div class="main-content app-content mt-0">
          <app-loader></app-loader>
            <div
              class="main-container container-fluid"
              (click)="toggleSwitcherBody()"
            >
              <!-- // contetnt  -->
              <router-outlet> </router-outlet>
            </div>
        </div>
      </div>
      <!-- right sidebar -->
      <app-right-sidebar></app-right-sidebar>
      <app-footer></app-footer>
      
    </div>
    <app-tab-to-top></app-tab-to-top>
  </div>
  