export const environment = {
  production: false,
  //url: 'http://192.168.3.99:2526',
  url: 'https://ambysoftapitest.ambacar.ec',
  recursosHumanosQueryApi: 'MS_RecursosHumanosQuery',
  recursosHumanosCommandApi: 'MS_RecursosHumanosCommand',
  nominaApi: '/api/Nomina/',

  parametrosGeneralesQueryApi: "MS_ParametrosGeneralesQuery",
  parametrosGeneralesCommandApi: "MS_ParametrosGeneralesCommand",
  parametrosGeneralesCobranzasApi: "/api/Cobranzas/",
  parametrosGeneralesContabilidadApi: "/api/Contabilidad/",

  cobranzasQueryApi: "MS_CobranzasQuery",
  cobranzasFideicomisosControllerApi: "/api/Fideicomisos/",
  cobranzasCommandApi: "MS_CobranzasCommand",

  clientesQueryApi: "MS_ClientesQuery",
  clientesCommandApi: "MS_ClientesCommand",
  clientesControllerApi: "/api/Clientes/",
  clientesZeusControllerApi: "/api/ClientesZeus/",

  contabilidadQueryApi: "MS_ContabilidadQuery",
  contabilidadCommandApi: "MS_ContabilidadCommand",
  dispositivosContaControllerApi: "/api/SolicitudesDispositivos/",
  dispositivosVHContaControllerApi: "/api/Vehiculos/",
  conciliacionesControllerApi: "/api/Conciliaciones/",

  logisticaQueryApi: "MS_LogisticaQuery",
  logisticaCommandApi: "MS_LogisticaCommand",

  prospeccionQueryApi: "MS_ProspeccionQuery",
  prospeccionCommandApi: "MS_ProspeccionCommand",
  prospeccionControllerApi: "/api/Prospecciones/",

  proteccionDatosQueryApi: "MS_ProteccionDatosQuery",
  proteccionDatosControllerApi: "/api/ReportesAutorizaciones/",

  vehiculoZeusQueryApi:"MS_VehiculosQuery",
  vehiculoZeusControllerApi:"/api/VehiculosZeus/",
  vehiculosQueryApi: "MS_VehiculosQuery",

  repuestosQueryApi: "MS_SeguridadesQuery",
  repuestosConsultaQueryApi: "MS_RepuestosQuery",
  repuestosCommandyApi: "MS_RepuestosCommand",

  reportesQueryApi: "MS_ReportesQuery",
  respuestosConsultaQueryApi: "MS_RepuestosQuery",


  seguridadesCommandApi:"MS_SeguridadesCommand",
  seguridadesQueryApi:"MS_SeguridadesQuery",
  usuariosControllerApi: "/api/Usuarios/",

  dispositivosLogisticaControllerApi: "api/DispositivosSolicitudes/",
  consultaIndividualQueryApi: "MS_ProteccionDatosQuery/",

  perfiladoresQueryApi: "MS_ProspeccionQuery",
  perfiladoresCommandApi: "MS_ProspeccionCommand",

  agGridKey: "Using_this_AG_Grid_Enterprise_key_( AG-044580 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Ambacar Cia Ltda )_is_granted_a_( Single Application )_Developer_License_for_the_application_( AmbySoft )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( AmbySoft )_need_to_be_licensed___( AmbySoft )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 4 July 2024 )____[v2]_MTcyMDA0NzYwMDAwMA==6195cd39f59347a340c76ae7bcc781af",

  urlProteccionDatosQr: 'https://protecciondatostest.ambacar.ec/proteccionDatos/autorizacion?identificacion=',

};

