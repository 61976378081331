<div ngbDropdown class="d-flex notifications dropdown">
    <a ngbDropdownToggle data-bs-toggle="dropdown" class="dropdown-toggle nav-link icon caret" aria-expanded="true">
        <i class="fe fe-bell"></i>
        <span class="pulse1 bg-success" *ngIf="tieneSinLeer"></span>
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
        <div ngbDropdownItem class="dropdown-item p-4 border-bottom" tabindex="0">
            <h5 class="border-bottom-0 mb-0 fs-16 font-weight-semibold"> Notificaciones </h5>
        </div>
        <div class="notifications-menu">
            <ng-container *ngIf="notificaciones && notificaciones.length>0">
                <a ngbDropdownItem class="d-flex"
                    *ngFor="let not of getNotificacionesRecientes()"
                    (click)="mostrarNotificacion(not)">
                    <div class="me-3 notifyimg {{ not.color ?? 'gb-primary' }} brround box-shadow">
                        <i class="{{ not.icono ?? 'fa fa-bell' }}"></i>
                    </div>
                    <div class="w-80">
                        <h6 class="notification-text mb-0 {{not.esLeida ? '' : 'fw-bold'}}" style="white-space: nowrap !important;"> {{not.mensaje}} </h6>
                        <span class="notification-subtext text-muted fs-12">{{ not.fecha | timeAgo }}</span>
                    </div>
                </a>
            </ng-container>
            <span ngbDropdownItem class="text-muted" *ngIf="!notificaciones || notificaciones.length<=0" >sin notificaciones</span>
        </div>
        <div class="dropdown-divider"></div>
        <a
            class="dropdown-item text-center pt-2 mb-2" routerLink="/dashboard/dashboard-index">Ver todas las notificaciones
        </a>
    </div>
</div>