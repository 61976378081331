import { Component, inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DirectorioService } from 'src/app/shared/services/directorio.service';

interface NumeroInfo {
  numero: string;
  esCelular: boolean;
  whatsapp: string | null;
  extension?: string; // Campo opcional para la extensión
}

@Component({
  selector: 'app-directorio',
  templateUrl: './directorio.component.html',
  styles: [
  ]
})
export class DirectorioComponent implements OnInit {

  private readonly toastr = inject(ToastrService);

  agencias: any[] = [];
  agenciasFiltradas: any[] = [];
  filtro: string = '';
  error: string | null = null;

  constructor(private directorioService: DirectorioService) {}

  ngOnInit(): void {
    this.directorioService.getDirectorio().subscribe(
      (data) => {
        this.agencias = data.agencias;
        this.agenciasFiltradas = this.agencias; // Inicialmente, las agencias no están filtradas
      },
      (err) => {
        this.error = err.message; // Almacenar el mensaje de error si ocurre
      }
    );
  }

  aplicarFiltro() {
    const filtroLower = this.filtro.toLowerCase();
    this.agenciasFiltradas = this.agencias.map(agencia => {
      return {
        ...agencia,
        directorio: agencia.directorio.filter((persona: { nombre: string; cargo: string; telefono: string | string[]; }) =>
          persona.nombre.toLowerCase().includes(filtroLower) ||
          persona.cargo.toLowerCase().includes(filtroLower)
        )
      };
    }).filter(agencia => agencia.directorio.length > 0);
  }
  
  copyText(elemento:string) {
    navigator.clipboard.writeText(elemento).then(() => {
      this.toastr.show('Copiado al portapapeles', "", {
        timeOut: 1000,
      });
    }).catch(err => {
      console.error('Error al copiar el texto: ', err);
    });
  }

  procesarNumeros(numeroTelefono: string | any): NumeroInfo[] {
    // Separar por comas o puntos y comas
    const numerosSinProcesar: string = numeroTelefono.toString();
    const numeros = numerosSinProcesar.split(/[,;]/);
  
    return numeros.map(num => {
      let esCelular = false;
      let numeroLimpio = num.trim();
      let numeroWhatsapp: string | null = null;
      let extension: string | undefined;
       
      if (!numeroLimpio) {
        return { numero: '', esCelular: false, whatsapp: '', extension: '' }; // Retorna null si está vacío
      }

      // Verificar si el número contiene una extensión
      const regexExtension = /(.*)\s*(ext[=:]?\s*\d+)/i;
      const match = numeroLimpio.match(regexExtension);

      if (match) {
        numeroWhatsapp = match[1].trim(); // Extraer el número antes de la extensión
        extension = ';' + match[2].replace(/ext[=:]?\s*/i, '').trim(); // Extraer y limpiar la extensión
      }

      // Reemplazar 593 o +593 por 0
      if (numeroLimpio.startsWith("593")) {
        numeroLimpio = "0" + numeroLimpio.slice(3);
      } else if (numeroLimpio.startsWith("+593")) {
        numeroLimpio = "0" + numeroLimpio.slice(4);
      }
  
      // Verificar si es celular (comienza con 09)
      if (numeroLimpio.startsWith("09")) {
        esCelular = true;
        numeroWhatsapp = "593" + numeroLimpio.slice(1);
      }
  
      if(!numeroWhatsapp){
        numeroWhatsapp = numeroLimpio;
      }
      
      return { numero: numeroLimpio, esCelular, whatsapp: numeroWhatsapp, extension };
    })
    .filter(numInfo => numInfo.numero);
  }
}
