<!-- FOOTER -->
<footer class="footer">
    <div class="container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-md-12 col-sm-12 text-center text-muted">
                <b>&copy;{{anioActual}} Ambacar CIA LTDA.</b>&nbsp;Powered by <b>Ambysoft &reg;</b>
            </div>
        </div>
    </div>
</footer>
<!-- FOOTER CLOSED -->