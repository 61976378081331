import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.development';
import { Login } from '../interfaces/Login.interfaces';
import { Observable } from 'rxjs';
import { TokenServicio } from '../interfaces/TokenServicio.interfaces';
import { RespuestaServiciosCommand } from 'src/app/interfaces/ServiciosGenerales/RespuestaServiciosCommand.interfaces';
import { CambiarClaveUsuario } from '../interfaces/CambiarClaveUsuario.interfaces';
import jwtDecode from 'jwt-decode';
import { JwtAmbyPayload } from 'src/app/shared/interfaces/JwtAmbyPayLoad.interface';

let url: string = environment.url;

const headerSA = {
  headers: {
    'Content-Type': 'application/json'
  }
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  get getToken():string {
    return localStorage.getItem('token') || '';
  }

  get getIp(): string {
    return localStorage.getItem('ipAddress') || '';
  }

  constructor(private router: Router, private http: HttpClient) {}

  login(usuario: string, contrasenia: string,): Observable<Login> {
    const body = { usuario, contrasenia };
    return this.http.post<Login>(`${url}/MS_SeguridadesCommand/api/Login/Login`, body, headerSA);
  }

  almacenarToken(token: string): Promise<boolean> {
    return new Promise((resolve) => {
      localStorage.setItem('token', token);
      this.obtenerDireccionIp();
      resolve(true)

    })
  }

  async obtenerDireccionIp() {
    var ipAddress = localStorage.getItem('ipAddress');
    if(!ipAddress){
      const inputValue = await fetch("//api.ipify.org/?format=json")
        .then(response => response.json())
        .then(data => data.ip)
      localStorage.setItem('ipAddress', inputValue);
    }
  }

  generarTokenQuery(ipAddress: string, nombreServicio: string): Observable<TokenServicio> {
    const body = { ipAddress, nombreServicio }
    return this.http.post<TokenServicio>(`${url}/MS_SeguridadesCommand/api/Token/Authenticate`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    }
    );
  }

  generarTokenCommand(ipAddress: string, nombreServicio: string): Observable<TokenServicio> {
    const body = { ipAddress, nombreServicio }
    return this.http.post<TokenServicio>(`${url}/MS_SeguridadesCommand/api/Token/Authenticate`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    }
    );
  }

  cambiarClave(datosClave: CambiarClaveUsuario, token: string) {
    let jsonData = JSON.stringify(datosClave)
    return this.http.post<RespuestaServiciosCommand>(`${url}/MS_SeguridadesCommand/api/Usuarios/ActualizarContrasenia`, jsonData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
  }

  singout(): void {
    for (let index = localStorage.length - 1; index >= 0; index--) {
      const k = localStorage.key(index) || '';
      if(!k.includes("modoObscuro") && !k.startsWith("Yoha") && !k.startsWith("Amby") && !k.includes("notDev") && !k.includes("notUsr")){
        localStorage.removeItem(k);
      }
    }
    this.router.navigateByUrl('/auth/login');
  }

  obtenerRolPermisoPagina(token: string, pathUrl: string) {
    return this.http.get<RespuestaServiciosCommand>(`${url}/MS_SeguridadesQuery/api/Menu/ObtenerPermisoRolPagina?PathUrl=${pathUrl}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
  )}

  isExpiredToken(): boolean {
    try {
      const token = this.getToken;
      if(token && token !== null ){
        const decoded = jwtDecode<JwtAmbyPayload>(token);
        const tokenExpired = Date.now() > decoded.exp! * 1000;
        return tokenExpired;
      }
      return true;
    }
    catch (error) {
      return true;
    }
  }

}
