import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Menu } from '../interfaces/Menu.interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';

let url: string = environment.url;
@Injectable({
  providedIn: 'root',
})

export class NavService implements OnDestroy {
  MENUITEMS: Menu[] = [];
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next;
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  recuperarMenu(token:string): Observable<Menu> {
    return this.http.get<Menu>(
      `${url}/MS_SeguridadesQuery/api/Menu/ObtenerMenuNuevo`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
  }

 
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
