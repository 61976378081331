import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ErrorHandler, Injectable, LOCALE_ID, isDevMode, } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule} from 'ngx-toastr';
import { NgxColorsModule } from 'ngx-colors';
import { ColorPickerModule } from 'ngx-color-picker';
import { LayoutService } from './shared/services/layout.service';
import { AuthInterceptor } from './authentication/interceptors/auth.interceptor';
import { ErrorInterceptor } from './authentication/interceptors/error.interceptor';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-EC';
import { ServiceWorkerModule } from '@angular/service-worker';

registerLocaleData(localeEs, 'es-EC')


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxColorsModule,
    ColorPickerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately'
    }),
  ],
  providers: [
    LayoutService,
    { provide: MAT_DATE_LOCALE, useValue: 'es-EC' },
    { provide: LOCALE_ID, useValue: 'es-EC' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
