import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appToggleTheme]'
})
export class ToggleThemeDirective {
  private body:HTMLBodyElement | any = document.querySelector('body');
  private inicializando:boolean = true;
  constructor() { }

  ngOnInit(){
    const modo = localStorage.getItem("modoObscuro");
    if(modo)
      this.toggleTheme();
    this.inicializando = false;
  }

  @HostListener('click') toggleTheme(){
    if (this.body != !this.body) {
      this.body.classList.toggle('dark-mode');
      this.body.classList.remove('bg-img1');
      this.body.classList.remove('bg-img2');
      this.body.classList.remove('bg-img3');
      this.body.classList.remove('bg-img4');
      if (!this.inicializando) {
        const modo = localStorage.getItem('modoObscuro');
        if (modo) {
          localStorage.removeItem('modoObscuro');
        } else {
          localStorage.setItem('modoObscuro', 'dark');
        }
      }
    }
  }
}
