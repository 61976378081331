import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, catchError, finalize, tap, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Response } from 'src/app/interfaces/ApiResponse/response.interfaces';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';
import { micro } from 'src/app/shared/routes/micros.enum';

const microFirstToken = [micro.seguridadesCommandApi, micro.seguridadesQueryApi]

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService, private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const bodyType = request.body;
    const url = request.url.split('/');
    const microReq = url[3];

    if(!(bodyType instanceof FormData) && !this._noLoadingMicros(microReq)) {
      Swal.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () =>
          Swal.showLoading()
      });
    }

    return next.handle(request).pipe(
      finalize(() => {
        Swal.close();
    }),
      tap((response) => {
        if(response instanceof HttpResponse && response.status === 200){
          const responseModel = response.body as Response;
          this._captureResponse200(responseModel);
        }
      }),

      catchError((error:HttpErrorResponse) => {
        this.errorsHttpCLient(error, microReq);
        return throwError(() => error);
      })
    )
  }

  private errorsHttpCLient(httpErrorResponse: HttpErrorResponse, microReq: string): void {
    switch(httpErrorResponse.status){
      case 500:
        break;
      case 400:
        break;
      case 401:
        this.authService.singout();
        break;
    }

    this.toastr.error(
      httpErrorResponse.error?.Message
      || httpErrorResponse.error?.label
      || 'Ocurrio un error inesperado, por favor póngase en contacto con el administrador del sistema',
      'Error',
      {
        timeOut: 3000,
      }
    );

  }

  private _captureResponse200(response: Response){
    if( !response.succeeded ){
      this.toastr.error(response.message)
    }
  }

  private _noLoadingMicros(micro:string):boolean {
    return microFirstToken.find(md => md == micro) != undefined;
  }

}
