// src/app/services/directorio.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DirectorioService {
  private url = 'https://ambysoftapi.ambacar.ec/archivos/otros/directorio.json'; // URL del archivo JSON

  constructor(private http: HttpClient) {}

  getDirectorio(): Observable<any> {
    const fecha = new Date();
    const year = fecha.getFullYear();
    const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const day = fecha.getDate().toString().padStart(2, '0');
    return this.http.get<any>(`${this.url}?v=${year}${month}${day}`);  }
}
