import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/authentication/guards/auth.guard';

export const content: Routes = [

  {

    path: 'dashboard',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'repuestos',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/repuestos/repuestos.module').then(
        (m) => m.RepuestosModule
      ),
  },
  {
    path: 'vehiculos',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/vehiculos/vehiculos.module').then(
        (m) => m.VehiculosModule
      ),
  },
  {
    path: 'talleres',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/talleres/talleres.module').then(
        (m) => m.TalleresModule
      ),
  },
  {
    path: 'recursosHumanos',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/recursos-humanos/recursos-humanos.module').then(
        (m) => m.RecursosHumanosModule
      ),
  },
  {
    path: 'contabilidad',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/contabilidad/contabilidad.module').then(
        (m) => m.ContabilidadModule
      ),
  },
  {
    path: 'usuarios',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'cobranzas',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/cobranzas/cobranzas.module').then(
        (m) => m.CobranzasModule
      ),
  },
  {
    path: 'marketing',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/marketing/marketing.module').then(
        (m) => m.MarketingModule
      ),
  },
  {
    path: 'logistica',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/logistica/logistica.module').then(
        (m) => m.LogisticaModule
      ),
  },
  {
    path: 'prospecciones',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/prospecciones/prospecciones.module').then(
        (m) => m.ProspecionesModule
      ),
  },
  {
    path: 'administracion',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/administracion/administracion.module').then(
        (m) => m.AdministracionModule
      ),
  },
  {
    path: 'importaciones',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/importaciones/importaciones.module').then(
        (m) => m.ImportacionesModule
      ),
  },

  {
    path: 'perfiladores',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/perfiladores/perfiladores.module').then(
        (m) => m.PerfiladoresModule
      ),
  },
  {
    path: 'proteccion-datos',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/proteccion-datos/proteccion-datos.module').then(
        (m) => m.ProteccionDatosModule
      ),
  },
  {
    path: 'crm',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('../../pages/crm/crm.module').then(
        (m) => m.CrmModule
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'auth/login',
  }
];
