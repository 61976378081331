import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(fecha: Date | string | number | undefined): string {
    if (!fecha) {
      return '';
    }

    const fechaActual = new Date();
    const fechaOrigen = new Date(fecha);
    const milisegundosDiferencia = fechaActual.getTime() - fechaOrigen.getTime();

    const minutos = Math.floor(milisegundosDiferencia / (1000 * 60));
    const horas = Math.floor(milisegundosDiferencia / (1000 * 60 * 60));
    const dias = Math.floor(milisegundosDiferencia / (1000 * 60 * 60 * 24));
    const semanas = Math.floor(dias / 7);
    const meses = Math.floor(dias / 30);
    const anios = Math.floor(dias / 365);

    if (minutos < 1) {
      return 'ahora';
    } else if (minutos < 60) {
      return `${minutos} minuto(s)`;
    } else if (horas < 24) {
      return `${horas} hora(s)`;
    } else if (dias < 7) {
      return `${dias} día(s)`;
    } else if (semanas < 4) {
      return `${semanas} semana(s)`;
    } else if (meses < 12) {
      return `${meses} mes(es)`;
    } else {
      return `${anios} año(s)`;
    }
  }
}