import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  listeningProcess: any;
  constructor(
    private authservice: AuthService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    const urlSplit = state.url.split('?')[0];

    if (urlSplit === '/dashboard/dashboard-app' || urlSplit === '/dashboard/dashboard-index') {
      return true;
    }
    
    if (this._validSession()) {

      const permit$ = this.authservice.obtenerRolPermisoPagina(this.authservice.getToken, urlSplit);
      const permit = await lastValueFrom(permit$);
      if(permit.succeeded && permit.data){
        return true;
      }else {
        return false
      }
    }
    return false;
  }

  private _validSession(): boolean {
    const isExpiredToken = this.authservice.isExpiredToken();
    if(isExpiredToken) {
      this.authservice.singout();
      return false;
    }
    return true;
  }
}

