<div class="app-header header sticky">
  <div class="container-fluid main-container">
    <div class="d-flex align-items-center justify-content-between   ">
      <a aria-label="Hide Sidebar" class="app-sidebar__toggle" data-bs-toggle="sidebar" (click)="toggleSidebar()">
        <i class="fe fe-menu" aria-hidden="true"></i>
      </a>
      <span class="logo-horizontal" >
        <img src="./assets/images/brand/logo.png" class="header-brand-img desktop-logo" alt="logo" />
        <img routerLink="/components/default-chat/dashboard/sales-dashboard" src="./assets/images/brand/logo-3.png"
          class="header-brand-img light-logo1" alt="logo" />
      </span>
      <!-- sidebar-toggle-->
      <!-- <div class="header-search d-none d-lg-flex">
        <div class="form-inline">
          <div class="search-element" style="display: none;">
            <input type="search" class="form-control header-search" placeholder="Buscar.." aria-label="Search"
              tabindex="1" [(ngModel)]="text" [ngModelOptions]="{standalone: true}" (keyup)="Search(text)"
              autocomplete="off" />
            <button class="btn btn-primary-color">
              <i class="fe fe-search" aria-hidden="true"></i>
            </button>
            <div class="search-result p-absolute w-100 card border mt-1" *ngIf="menuItems?.length; else elseBlock">
              <div class="card-header">
                <h4 class="card-title me-2">Search result of "{{text}}" </h4> <small> limit dropdown 5 only</small>
              </div>
              <ul class="list-group text-break">
                <li *ngFor="let menuItem of menuItems | slice:0:5" [routerLink]="menuItem?.path"
                  class="list-group-item header-list-group border-start-end-top-0 br-0" (click)="clearSearch()">
                  <i class="fa fa-angle-double-right me-2" aria-hidden="true"></i>{{menuItem?.title}}
                </li>
              </ul>
            </div>
            <ng-template #elseBlock>
              <ul class="list-group p-absolute w-100 mt-1 serach-result-empty text-break">
                <li class="list-group-item no-result" [ngClass]="{'show':SearchResultEmpty}"><i
                    class="fa fa-frown-o text-primary me-2" aria-hidden="true"></i>No Search Result Found with
                  "{{text}}"
                </li>
              </ul>
            </ng-template>
          </div>
        </div>
      </div> -->

      <img src="./assets/images/brand/logo.png" class="header-brand-img desktop-logo" alt="logo" />
      <div class="d-flex order-lg-2   header-search-icon">
        <button class="navbar-toggler navresponsive-toggler d-lg-none ms-auto collapsed" type="button"
          (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"
            class="navbar-toggler-icon">
            <path d="M0 0h24v24H0V0z" fill="none"></path>
            <path
              d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z">
            </path>
          </svg>
        </button>
        <div class="dropdown d-none">
          <a href="javascript:void(0)" class="nav-link icon" data-bs-toggle="dropdown">
            <i class="fe fe-search"></i>
          </a>
          <div class="dropdown-menu header-search dropdown-menu-start">
            <div class="input-group w-100 p-2">
              <input type="text" class="form-control" placeholder="Search...." />
              <div class="input-group-text btn btn-primary">
                <i class="fe fe-search" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar navbar-collapse responsive-navbar p-0 ">
          <div class="collapse navbar-collapse" id="navbarSupportedContent-4" #collapse="ngbCollapse"
            [(ngbCollapse)]="isCollapsed" [horizontal]="true">
            <div class="d-flex">
              <div class="d-flex country">
                <a class="nav-link icon " appToggleTheme>
                  <span class="dark-layout"><i class="fe fe-moon"></i></span>
                  <span class="light-layout"><i class="fe fe-sun"></i></span>
                </a>
              </div>
              <!-- Theme-Layout -->
              <div class="dropdown d-flex">
                <a class="nav-link icon full-screen-link nav-link-bg" appFullscreen>
                  <i class="fe fe-minimize fullscreen-button"></i>
                </a>
              </div>
              <!-- FULL-SCREEN -->
              <!-- <div ngbDropdown class=" dropdown d-flex notifications">
                <a ngbDropdownToggle data-bs-toggle="dropdown" class="nav-link icon caret">
                 <i class="fe fe-bell "></i>
                  <span class="pulse1 bg-success"></span>
                </a>
                <div ngbDropdownMenu
                  class="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                >
                  <div ngbDropdownItem class="p-4 border-bottom">
                    <h5 class="border-bottom-0 mb-0 fs-16 font-weight-semibold">
                      Notifications
                    </h5>
                  </div>
                  <div class="notifications-menu">
                    <a  class="d-flex" routerLink="/apps/mails/mail-inbox">
                      <div
                        class="me-3 notifyimg bg-primary brround box-shadow-primary"
                      >
                        <i class="fe fe-mail"></i>
                      </div>
                      <div class="w-80">
                        <h6 class="notification-label mb-0 text-wrap">
                          Commented on your post
                        </h6>
                        <span class="notification-subtext text-muted fs-12"
                          >3 days ago</span
                        >
                      </div>
                    </a>
                    <a class="dropdown-item d-flex"  routerLink="/apps/mails/mail-inbox">
                      <div
                        class="me-3 notifyimg bg-secondary brround box-shadow-secondary"
                      >
                        <i class="fe fe-download"></i>
                      </div>
                      <div class="w-80">
                        <h6 class="notification-label mb-0 text-wrap">
                          New file has been Uploaded
                        </h6>
                        <span class="notification-subtext text-muted fs-12"
                          >3 days ago</span
                        >
                      </div>
                    </a>
                    <a class="dropdown-item d-flex"  routerLink="/apps/mails/mail-inbox">
                      <div
                        class="me-3 notifyimg bg-warning brround box-shadow-warning"
                      >
                        <i class="fe fe-user"></i>
                      </div>
                      <div class="w-80">
                        <h6 class="notification-label mb-0 text-wrap">
                          User account has Updated
                        </h6>
                        <span class="notification-subtext text-muted fs-12"
                          >2 days ago</span
                        >
                      </div>
                    </a>
                    <a class="dropdown-item d-flex" routerLink="/apps/mails/mail-inbox">
                      <div
                        class="me-3 notifyimg bg-danger brround box-shadow-danger"
                      >
                        <i class="fe fe-shopping-cart"></i>
                      </div>
                      <div class="w-80">
                        <h6 class="notification-label mb-0 text-wrap">
                          New Order Recevied
                        </h6>
                        <span class="notification-subtext text-muted fs-12"
                          >1 hour ago</span
                        >
                      </div>
                    </a>
                    <a class="dropdown-item d-flex" routerLink="/apps/mails/mail-inbox">
                      <div
                        class="me-3 notifyimg bg-info brround box-shadow-info"
                      >
                        <i class="fe fe-server"></i>
                      </div>
                      <div class="w-80">
                        <h6 class="notification-label mb-0 text-wrap">
                          Server Rebooted
                        </h6>
                        <span class="notification-subtext text-muted fs-12"
                          >2 hour ago</span
                        >
                      </div>
                    </a>
                  </div>
                  <div class="dropdown-divider"></div>
                  <a
                    routerLink="/apps/mails/mail-inbox"
                    class="dropdown-item text-center pt-2 mb-2"
                    >View all Notification</a
                  >
                </div>
              </div> -->
              <!-- DIRECTORIO -->
              <app-directorio></app-directorio>
              <!-- NOTIFICATIONS -->
              <app-notificacion></app-notificacion>
              <!-- <div ngbDropdown class="dropdown d-flex message">
                <a  ngbDropdownToggle class="nav-link icon" data-bs-toggle="dropdown" >
                    <i class="fe fe-message-square"></i>
                  <span class="nav-unread badge bg-danger rounded-pill pulse"
                    >3</span>
                </a>
                <div ngbDropdownMenu
                  class="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                >
                  <div ngbDropdownItem class="p-4 border-bottom" >
                    <h5 class="border-bottom-0 mb-0 fs-16 font-weight-semibold">
                      Messages
                    </h5>
                  </div>
                  <div class="message-menu message-menu-scroll message-menu">
                    <a class="dropdown-item d-flex">
                      <span
                        class="avatar brround me-3 cover-image"
                        data-bs-image-src="./assets/images/users/1.jpg"
                        style="
                          background: url('./assets/images/users/1.jpg') center
                            center;
                        "
                      >
                      </span>
                      <div class="w-90">
                        <div class="d-flex">
                          <h6 class="my-1 text-break">Madeleine</h6>
                          <span class="text-muted ms-auto text-wrap fs-12">
                            35 sec ago
                          </span>
                        </div>
                        <span class="text-muted fs-13"
                          >Hey! there I' am available..</span
                        >
                      </div>
                    </a>
                    <a class="dropdown-item d-flex" routerlink="/components/default-chat">
                      <span
                        class="avatar brround me-3 cover-image"
                        data-bs-image-src="./assets/images/users/2.jpg"
                        style="
                          background: url('./assets/images/users/2.jpg') center
                            center;
                        "
                      >
                      </span>
                      <div class="w-90">
                        <div class="d-flex">
                          <h6 class="my-1 text-break">Anthony</h6>
                          <span class="text-muted ms-auto text-wrap fs-12">
                            15 mins ago
                          </span>
                        </div>
                        <span class="text-muted fs-13"
                          >New product Launching...</span
                        >
                      </div>
                    </a>
                    <a class="dropdown-item d-flex" routerlink="/components/default-chat">
                      <span
                        class="avatar brround me-3 cover-image"
                        data-bs-image-src="./assets/images/users/9.jpg"
                        style="
                          background: url('./assets/images/users/9.jpg') center
                            center;
                        "
                      >
                      </span>
                      <div class="w-90" >
                        <div class="d-flex">
                          <h6 class="my-1 text-break">Olivia</h6>
                          <span class="text-muted ms-auto text-wrap fs-12">
                            2 hrs ago
                          </span>
                        </div>
                        <span class="text-muted fs-13"
                          >New Schedule Realease...</span
                        >
                      </div>
                    </a>
                    <a class="dropdown-item d-flex" routerlink="/components/default-chat">
                      <span
                        class="avatar brround me-3 cover-image"
                        data-bs-image-src="./assets/images/users/10.jpg"
                        style="
                          background: url('./assets/images/users/10.jpg')
                            center center;
                        "
                      >
                      </span>
                      <div class="w-90">
                        <div class="d-flex">
                          <h6 class="my-1 text-break">Sanderson</h6>
                          <span class="text-muted ms-auto text-wrap fs-12">
                            4 hrs ago
                          </span>
                        </div>
                        <span class="text-muted fs-13"
                          >New Schedule Realease...</span
                        >
                      </div>
                    </a>
                  </div>
                  <div class="dropdown-divider m-0"></div>
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item text-center"
                    >See all Messages</a
                  >
                </div>
              </div> -->
              <!-- MESSAGE-BOX -->
              <!-- <div ngbDropdown class="dropdown d-flex country-selector ">
                <a ngbDropdownToggle
                  href="javascript:void(0);"
                  class="d-flex nav-link icon leading-none"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <img
                    src="./assets/images/flags/us_flag.jpg"
                    alt="img"
                    class="align-self-center"
                  />
                </a>
                <div ngbDropdownMenu
                  class="dropdown-menu"
                >
                  <a ngbDropdownItem
                    href="javascript:void(0);"
                    class="dropdown-item d-flex pb-2 px-4"
                  >
                    <img
                      src="./assets/images/flags/french_flag.jpg"
                      alt="flag-img"
                      class="avatar me-3 align-self-center"
                    />
                    <div class="countries">
                      <strong>French</strong>
                    </div>
                  </a>
                  <a ngbDropdownItem
                    href="javascript:void(0);"
                    class="dropdown-item d-flex pb-2 px-4"
                  >
                    <img
                      src="./assets/images/flags/germany_flag.jpg"
                      alt="flag-img"
                      class="avatar me-3 align-self-center"
                    />
                    <div class="countries">
                      <strong>Germany</strong>
                    </div>
                  </a>
                  <a ngbDropdownItem
                    href="javascript:void(0);"
                    class="dropdown-item d-flex pb-2 px-4"
                  >
                    <img
                      src="./assets/images/flags/italy_flag.jpg"
                      alt="flag-img"
                      class="avatar me-3 align-self-center"
                    />
                    <div class="countries">
                      <strong>Italy</strong>
                    </div>
                  </a>
                  <a ngbDropdownItem
                    href="javascript:void(0);"
                    class="dropdown-item d-flex pb-2 px-4"
                  >
                    <img
                      src="./assets/images/flags/russia_flag.jpg"
                      alt="flag-img"
                      class="avatar me-3 align-self-center"
                    />
                    <div class="countries">
                      <strong>Russia</strong>
                    </div>
                  </a>
                  <a ngbDropdownItem
                    href="javascript:void(0);"
                    class="dropdown-item d-flex pb-2 px-4"
                  >
                    <img
                      src="./assets/images/flags/spain_flag.jpg"
                      alt="flag-img"
                      class="avatar me-3 align-self-center"
                    />
                    <div class="countries">
                      <strong>Spain</strong>
                    </div>
                  </a>
                </div>
              </div> -->
              <!-- country -->
              <div ngbDropdown class="dropdown profile-1 d-flex ">
                <a ngbDropdownToggle data-bs-toggle="dropdown"
                  class="nav-link icon leading-none d-flex">
                  <img src="./assets/images/users/user.png" alt="profile-user"
                    class="avatar profile-user brround cover-image" />
                </a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <span ngbDropdownItem>{{nombreUsuario}}</span>
                  <span ngbDropdownItem class="text-muted" ><small>{{rol}}</small></span>
                  <!-- <a ngbDropdownItem routerLink="/pages/profile">
                    <i class="dropdown-icon mdi mdi-account-outline"></i> Mi Perfil</a> -->
                    <a ngbDropdownItem routerLink="/usuarios/cambiar-clave">
                    <i class="dropdown-icon zmdi zmdi-edit"></i>Cambiar Clave</a>
                    <a ngbDropdownItem routerLink="/dashboard/dashboard-app">
                    <i class="dropdown-icon mdi mdi-compass-outline"></i> Necesitas Ayuda?</a>
                    <a ngbDropdownItem (click)="cerrarSesion()">
                    <i class="dropdown-icon mdi mdi-logout-variant"></i>Cerrar Sesión</a>
                </div>
              </div>
              <!-- Profile -->
              <!-- <div class="dropdown d-flex header-settings">
                <a (click)="toggleSidebarNotification()"
                  href="javascript:void(0);"
                  class="nav-link icon"
                  data-bs-toggle="sidebar-right"
                  data-bs-target=".sidebar-right"
                >
                 <i class="fe fe-align-right"></i>
                </a>
              </div> -->
              <!-- Horizontal SIDE-MENU -->
            </div>
          </div>
        </div>
        <!-- <div class="switch-icon nav-link icon" (click)="toggleSwitcher()">
          <i class="fe fe-settings fa-spin text_primary"></i>
        </div> -->
      </div>
    </div>
  </div>
</div>
