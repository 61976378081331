export enum micro {

  // micros principales
  clientesQueryApi=                     "MS_ClientesQuery",
  clientesCommandApi=                   "MS_ClientesCommand",
  cobranzasQueryApi=                    "MS_CobranzasQuery",
  cobranzasCommandApi=                  "MS_CobranzasCommand",
  contabilidadQueryApi=                 "MS_ContabilidadQuery",
  contabilidadCommandApi=               "MS_ContabilidadCommand",
  importacionQuery=                     "MS_ImportacionQuery",
  importacionCommand=                   "MS_ImportacionCommand",
  logisticaQueryApi=                    "MS_LogisticaQuery",
  logisticaCommandApi=                  "MS_LogisticaCommand",
  parametrosGeneralesQueryApi=          "MS_ParametrosGeneralesQuery",
  parametrosGeneralesCommandApi=        "MS_ParametrosGeneralesCommand",
  prospeccionQueryApi=                  "MS_ProspeccionQuery",
  prospeccionCommandApi=                "MS_ProspeccionCommand",
  proteccionDatosQueryApi=              "MS_ProteccionDatosQuery",
  recursosHumanosQueryApi=              "MS_RecursosHumanosQuery",
  recursosHumanosCommandApi=            "MS_RecursosHumanosCommand",
  reportesQueryApi=                     "MS_ReportesQuery",
  reportesCommandApi=                   "MS_ReportesCommand",
  repuestosQueryApi=                    "MS_RepuestosQuery",
  repuestosCommandApi=                  "MS_RepuestosCommand",
  vehiculosQueryApi=                    "MS_VehiculosQuery",
  talleresQuery=                        "MS_TalleresQuery",
  seguridadesCommandApi=                "MS_SeguridadesCommand",
  seguridadesQueryApi=                  "MS_SeguridadesQuery",

  // controllers
  nominaApi=                            "/api/Nomina/",
  parametrosGeneralesCobranzasApi=      "/api/Cobranzas/",
  parametrosGeneralesContabilidadApi=   "/api/Contabilidad/",
  parametrosGeneralesControllerApi=     "/api/Generales/",
  cobranzasFideicomisosControllerApi=   "/api/Fideicomisos/",

  clientesControllerApi=                 "/api/Clientes/",
  clientesZeusControllerApi=             "/api/ClientesZeus/",
  clienteZeusControllerApi=              "/api/ClientesZeus/",
  clienteCRMControllerApi=               "/api/ClienteCRM/",

  dispositivosContaControllerApi=        "/api/SolicitudesDispositivos/",
  dispositivosVHContaControllerApi=      "/api/Vehiculos/",
  conciliacionesControllerApi=           "/api/Conciliaciones/",
  financiamientoControllerApi=           "/api/Financiamiento/",
  solicitudesAnulacionFacturaController= "/api/SolicitudesAnulacionFactura/",
  negociacionSolicitudController=        "/api/NegociacionSolicitud/",
  prefacturaController=                  "/api/Prefactura/",
  facturasController=                    "/api/Facturas/",
  testDriveController=                   "/api/TestDrive/",
  transferenciaController=               "/api/Transferencia/",

  solicitudNegocioController=            "/api/SolicitudNegocio/",
  solicitudProcesoController=            "/api/SolicitudProceso/",
  pagoNegocioController=                 "/api/PagoNegocio/",
  formaPagoController=                   "/api/FormaPago/",
  negociacionVigenteController=          "/api/NegociacionVigente/",
  vehivulosRegimenController=            "/api/VehiculosRegimen/",

  prospeccionControllerApi=              "/api/Prospecciones/",
  perfiladoresControllerApi=             "/api/Perfiladores/",
  asesoresControllerApi=                 "/api/Asesores/",
  aignacionesControllerApi=              "/api/Asignaciones/",
  kardexControllerApi=                   "/api/Kardex/",
  histAsigControllerApi=                 "/api/HistoricoAsignacion/",
  consignacionControllerApi=             "/api/Consignacion/",
  histSolicitudControllerApi=            "/api/HistoricoSolicitud/",
  solicitudVehiculosControllerApi=       "/api/SolicitudVehiculos/",
  presencialProspeccionControllerApi=    "/api/PresencialProspeccion/",
  desistimientoControllerApi=            "/api/Desistimiento/",
  exoneradosControllerApi=               "/api/Exonerados/",
  campaniasControllerApi=                "/api/Campanias/",
  gestionProspeccionControllerApi=       "/api/GestionProspeccion/",
  cotizacionesControllerApi=             "/api/Cotizaciones/",
  horarioControllerApi=                  "/api/Horario/",


  proteccionDatosControllerApi=          "/api/ReportesAutorizaciones/",
  vehiculoZeusControllerApi=             "/api/VehiculosZeus/",
  solicitudController=                   "/api/SolicitudAgencia/",
  pedidoAgenciaController=               "/api/PedidoAgencia/",
  pedidoAgenciaAsignacionController=     "/api/PedidoAgenciaAsignacion/",
  pedidoAgenciaCourierController=        "/api/PedidoAgenciaCourier/",
  pedidoAgenciaMovilizacionController=   "/api/PedidoAgenciaMovilizacion/",
  pedidoAgenciaRecepcionController=      "/api/PedidoAgenciaRecepcion/",
  pedidosAgenciasInconformesController=  "/api/PedidosAgenciasInconformidades/",
  packingCEDIController=                 "/api/PackingCEDI/",
  solicitudesCEDIController=             "/api/SolicitudesCEDI/",
  pickingCEDIController=                 "/api/PickingCEDI/",
  inconformidadesController=             "/api/Inconformidades/",
  reporteContabilidadController=         "/api/Contabilidad/",
  agenciaController=                     "/api/Agencia/",
  consecionarioController=               "/api/Consecionario/",
  reportesCEDIController=                "/api/ReportesCedi/",
  logsControllerApi=                     "/api/Logs/",
  usuariosControllerApi=                 "/api/Usuarios/",
  personasControllerApi=                 "/api/Personas/",
  personaControllerApi=                  "/api/Persona/",

  dispositivosLogisticaControllerApi=    "/api/DispositivosSolicitudes/",
  importacionControllerApi=              "/api/ProcesoImportacion/",
  cargasFamiliaresController  =          "/api/CargasFamiliares/",

}
